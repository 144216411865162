import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        background: '#ffc6d9',
        borderRadius: 3,
        border: 0,
        color: '#243651',
        // height: 48,
        padding: '8px 48px',
        '&:hover': {
            background: '#78afbd',
        },
    },
    label: {
        fontSize: 24,
        textTransform: 'uppercase',
    },
})

export default ({ children, onClick }) => {
    const styles = useStyles()
    return (
        <Button
            onClick={onClick}
            classes={{
                root: styles.root,
                label: styles.label,
            }}
        >
            {children}
        </Button>
    )
}
