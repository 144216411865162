import React, { useEffect, useReducer } from 'react'
import rp from 'request-promise'
import { makeStyles } from '@material-ui/styles'
import Suggest from './Suggest'
import update, { extend } from 'immutability-helper'
import Button from './Button'
import Chip from './Chip'
import { pipe, applyTo, prop, sortBy, propEq, map, findIndex } from 'ramda'

const useStyles = makeStyles({
    app: {},
    h1: {
        color: '#243651',
        textTransform: 'uppercase',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '3fr 2fr',
        height: '100vh',
    },
    suggest: {
        gridColumn: '1 / span 1',
        gridRow: '1 / span 1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        background: '#78afbd',
    },
    items: {
        gridColumn: '2 / span 1',
        gridRow: '1 / span 1',
        background: '#916c80',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    item: {
        margin: '16px 0',
    },
    action: {
        gridRow: '2 / span 1',
        gridColumn: '1 / span 2',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#271f30',
    },
})

extend('$sort', function(_, original) {
    return applyTo(original, sortBy(prop('title')))
})

const zap = ({ selectedItems, dispatch }) => () => {
    rp({
        uri: `${process.env.REACT_APP_API_URI}/artworks/mark`,
        method: 'POST',
        body: {
            ids: map(prop('_id'), selectedItems),
        },
        json: true,
    })
    dispatch({ type: 'ZAPPED', selectedItems })
}

const initialState = { selectedItems: [], artworks: [] }

function reducer(state, action) {
    console.log(action.type)
    switch (action.type) {
        case 'PUSH_SELECTED_ITEM': {
            const { item } = action
            return update(state, {
                selectedItems: { $push: [item] },
                artworks: {
                    $splice: [
                        [findIndex(propEq('_id', item._id), state.artworks), 1],
                    ],
                },
            })
        }
        case 'REMOVE_ITEM': {
            const { item } = action
            return update(state, {
                selectedItems: {
                    $splice: [
                        [
                            findIndex(
                                propEq('_id', item._id),
                                state.selectedItems
                            ),
                            1,
                        ],
                    ],
                },
                artworks: {
                    $push: [item],
                    $sort: true,
                },
            })
        }
        case 'ZAPPED': {
            const { selectedItems } = action
            const splices = map(
                pipe(
                    prop('_id'),
                    id => findIndex(propEq('_id', id), state.artworks),
                    index => [index, 1]
                ),
                selectedItems
            )
            return update(state, {
                selectedItems: { $set: [] },
                artworks: {
                    $splice: [...splices],
                },
            })
        }
        case 'SET_ARTWORKS': {
            const { items } = action
            return update(state, {
                artworks: { $set: items },
            })
        }
        default:
            throw new Error()
    }
}

export default () => {
    const styles = useStyles()
    // const [artworks, setArtworks] = useState([])
    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        const fetch = async () => {
            const items = await rp({
                uri: `${process.env.REACT_APP_API_URI}/artworks`,
                json: true,
            })
            dispatch({
                type: 'SET_ARTWORKS',
                items: sortBy(prop('title'), items),
            })
        }
        fetch()
    }, [])

    const { artworks } = state

    return (
        <div className={styles.app}>
            <div className={styles.grid}>
                <div className={styles.suggest}>
                    <h1 className={styles.h1}>Pecub Zapper</h1>
                    <Suggest artworks={artworks} dispatch={dispatch} />
                </div>

                <div className={styles.items}>
                    {state.selectedItems.map(item => (
                        <div className={styles.item} key={item._id}>
                            <Chip
                                label={item.title}
                                onDelete={() =>
                                    dispatch({ type: 'REMOVE_ITEM', item })
                                }
                            />
                        </div>
                    ))}
                </div>
                <div className={styles.action}>
                    <Button
                        onClick={zap({
                            selectedItems: state.selectedItems,
                            dispatch,
                        })}
                    >
                        Zap
                    </Button>
                </div>
            </div>
        </div>
    )
}
