import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import Downshift from 'downshift'

const useStyles = makeStyles({
    input: {
        fontSize: 24,
        borderRadius: 6,
        outline: 'none',
        padding: 12,
        width: 200,
        background: 'transparent',
        border: 'solid 5px #243651',
        color: '#243651',
    },
    line: {
        fontSize: 24,
        margin: '16px 0',
        padding: '16px',
        background: '#243651',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
        '&:hover': {
            cursor: 'pointer',
        },
    },
})

export default ({ artworks, dispatch }) => {
    const styles = useStyles()
    const input = useRef()

    input.current && input.current.focus()

    return (
        <Downshift
            onSelect={value => {
                dispatch({ type: 'PUSH_SELECTED_ITEM', item: value })
            }}
            itemToString={item => (item ? item.title : '')}
        >
            {({
                getInputProps,
                getItemProps,
                getMenuProps,
                isOpen,
                inputValue,
                reset,
            }) => (
                <div>
                    <input
                        {...getInputProps()}
                        className={styles.input}
                        ref={input}
                    />
                    <div {...getMenuProps()}>
                        {isOpen
                            ? artworks
                                  .filter(
                                      item =>
                                          !inputValue ||
                                          item.title.includes(inputValue)
                                  )
                                  .map((item, index) => (
                                      <div
                                          className={styles.line}
                                          {...getItemProps({
                                              key: item._id,
                                              index,
                                              item,
                                          })}
                                          onClick={() => {
                                              dispatch({
                                                  type: 'PUSH_SELECTED_ITEM',
                                                  item,
                                              })
                                              reset()
                                          }}
                                      >
                                          {item.title}
                                      </div>
                                  ))
                            : null}
                    </div>
                </div>
            )}
        </Downshift>
    )
}
