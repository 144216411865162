import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { IconButton } from '@material-ui/core'

const useStyles = makeStyles({
    chip: {
        padding: '16px 32px',
        fontSize: 24,
        borderRadius: 6,
        border: 'solid 5px #ffc6d9',
        color: '#ffc6d9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    svg: {
        width: 24,
    },
    button: {
        marginLeft: 24,
    },
})

export default ({ label, onDelete }) => {
    const styles = useStyles()
    const fill = '#ffc6d9'
    return (
        <div className={styles.chip}>
            {label}
            <IconButton className={styles.button} onClick={onDelete}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={styles.svg}
                    viewBox="0 0 24 24"
                >
                    <path
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                        fill={fill}
                    />
                </svg>
            </IconButton>
        </div>
    )
}
